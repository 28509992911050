import React, {useEffect, useState} from 'react'
import Link from 'next/link'

import {useTranslation} from '~/i18n'
import {localStorage} from '../modules/storage'

export default function PrivacyPermissionCard() {
  const {t} = useTranslation('common')
  const [hasAccepted, hasAcceptedSet] = useState(null)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    hasAcceptedSet(JSON.parse(localStorage.getItem('privacy-permission') === 'true'))
  }, [])

  if (hasAccepted || hasAccepted === null) {
    return null
  }

  return (
    <React.Fragment>
      <div className="z-[9999] fixed bottom-0 left-0 w-full">
        <div className="relative flex justify-center items-center p-3">
          <div className="absolute w-full h-full bg-background-modal -z-1" />
          <div className="max-w-6xl flex flex-col md:flex-row items-center md:gap-2">
            <div>
              <span className="text-true-white">
                {t('common:privacy.message')}
              </span>
              <Link href="/privacy">
                <a
                  target="_blank"
                  className="underline text-true-white hover:text-true-white">
                  {t('common:privacy.privacy_policy')}
                </a>
              </Link>
              <span className="text-true-white">{t('common:privacy.message_suffix')}</span>
            </div>
            <button
              onClick={() => {
                hasAcceptedSet(true)
                localStorage.setItem('privacy-permission', true)
              }}
              className="px-3 py-1 w-full md:w-auto mt-3 md:mt-0 rounded border border-true-white text-true-white">
              {t('common:privacy.accept')}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}