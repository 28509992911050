import gql from '~/modules/graphql/gql'

export default {
  Post: gql`
    fragment PostFragment on Post {
      id
      post_status
      post_title
      post_excerpt
      post_date
      post_modified
      thumbnail {
        src
        height
        width
        sizes(sizes: ["medium", "large"]) {
          size
          width
          height
          src
        }
      }
    }
  `,
}
