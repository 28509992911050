import gql from '~/modules/graphql/gql'
import fragments from './fragments'

export default gql`
  query Archives($year: Int, $month: Int, $before: Int, $withPosts: Boolean = true) {
    archives(year: $year, month: $month) {
      year
      month
      posts {
        count
        range(before: $before, limit: 12) @include(if: $withPosts) {
          has_before
          data {
            ...PostFragment
          }
        }
      }
    }
  }

  ${{fragments}}
`
