export default [
  {
    title: 'Home',
    href: '/',
    extras: {logoBrand: true},
    shows: ['menuhead', 'menuside'],
  },
  {
    title: 'Brand News',
    href: '/category/[slug]',
    as: '/category/brand-news',
    extras: {termId: 2746},
    shows: ['menuhead', 'menuside'],
  },
  {
    title: 'Brand Story',
    href: '/category/[slug]',
    as: '/category/brand-story',
    extras: {termId: 2747},
    shows: ['menuhead', 'menuside'],
  },
  {
    title: 'Brand Strategy',
    href: '/category/[slug]',
    as: '/category/brand-strategy',
    extras: {termId: 2748},
    shows: ['menuhead', 'menuside'],
  },
  {
    title: 'Infographic',
    href: '/category/[slug]',
    as: '/category/infographic',
    extras: {termId: 2749},
    shows: ['menuhead', 'menuside'],
  },
  {
    template: 'archive',
    shows: ['menuside'],
  },
  {
    title: 'About',
    href: '/about',
    as: '/about',
    extras: {},
    shows: ['menuhead', 'menuside'],
  },
  {
    title: 'Careers',
    href: 'https://www.ltmh.com/careers',
    as: 'https://www.ltmh.com/careers',
    extras: {},
    shows: ['menuhead', 'menuside'],
    target: '_blank',
    rel: 'noopener noreferrer',
  },
]
