import React, {useState, useEffect, useRef} from 'react'
import Link from 'next/link'
import {useRouter} from 'next/router'
import clsx from 'clsx'

import {sendGtagEvent} from '../../modules/ga'
import {useMenus, isMenuActive} from '~/modules/menus'
import LogoBrand from '~/components/LogoBrand'

function useSearch() {
  const router = useRouter()
  const querySearch = router.query.s ?? ''

  const [search, searchSet] = useState(querySearch)
  const [searchExpand, searchExpandSet] = useState(querySearch !== '')

  useEffect(() => {
    if (querySearch !== search) {
      searchSet(querySearch)
      searchExpandSet(querySearch !== '')
    }
  }, [querySearch])

  return {
    searchExpand,
    onSearchToggle: () => {
      const next = !searchExpand

      searchExpandSet(next)

      return next
    },
    search,
    searchSet,
  }
}


const SearchInput = ({
  mobile = false, inputEl, searchSet, search, searchExpand, onSearchSubmit,
}) => (
  <div className={clsx('relative', {'hidden md:block': !mobile}, {'w-full': mobile})}>
    <input
      id="nav-search"
      ref={inputEl}
      onChange={e => searchSet(e.target.value)}
      value={search}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          onSearchSubmit()
        }
      }}
      placeholder="Search"
      className={clsx('search-input text-true-white bg-nav border rounded-3xl border-true-white focus:border-secondary focus:outline-none transition-all ease-in-out duration-300', {'active border py-2 pl-4 pr-12': searchExpand}, {'hidden': !searchExpand}, {'w-full': mobile}, {'w-64 ml-4 mr-2': !mobile})} />
    {search && searchExpand && (
      <div
        className="clear-icon absolute right-6 top-3 cursor-pointer text-secondary bc-ic-clear_20 hidden"
        onClick={() => searchSet('')} />
    )}
    <style jsx>{`
    .search-input:focus + .clear-icon
    {
      display: block
    }
    `}</style>
  </div>
)


function MenuHead({onSearch, onClickMenu}) {
  const router = useRouter()
  const menus = useMenus()

  const {searchExpand, onSearchToggle, search, searchSet} = useSearch()

  const inputEl = useRef(null)
  const inputElMobile = useRef(null)

  const onSearchClick = () => onSearchToggle()

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.focus()
    }
    if (inputElMobile.current) {
      inputElMobile.current.focus()
    }
  }, [inputEl.current, inputElMobile.current])

  const onSearchSubmit = e => {
    e?.preventDefault()
    if (search === '') {
      return
    }
    onSearch(search)
    if (inputEl.current) {
      inputEl.current.blur()
    }
    if (inputElMobile.current) {
      inputElMobile.current.blur()
    }
  }


  return (
    <React.Fragment>
      <div className="bg-nav flex h-navbar justify-between items-center px-4">
        <div className="flex gap-x-6">
          <div>
            <button
              className="min-w-0 p-2 text-true-white focus:outline-none flex-shrink-0"
              onClick={onClickMenu}
              aria-label="menu"
              data-testid="layouts/default/Menu/bars">
              <div className="bc-ic-menu text-3xl" />
            </button>

          </div>
          <div><Link href="/"><a><LogoBrand /></a></Link></div>
        </div>
        <div
          className="flex items-center gap-x-6"
          data-testid="layouts/default/MenuHead">
          <div className={clsx('hidden md:flex justify-evenly gap-10', {'md:hidden': searchExpand})}>
            {menus
              .filter(({shows}) => shows.includes('menuhead'))
              .map((menu, i) => (
                <Link
                  key={menu.as ?? menu.href}
                  href={menu.href}
                  as={menu.as}>
                  <a
                    target={menu.target}
                    className={clsx('text-true-white font-bold font-fc hover:text-secondary py-4', {'text-secondary': isMenuActive({menu, router})})}
                    onClick={() => sendGtagEvent('click', {category: 'layouts/default/MenuHead', label: `menu-offset-${i}`})}
                    ref={menu.rel}>
                    {menu.title}
                  </a>
                </Link>
              ))
            }
          </div>
          <div className="basis-0 flex-grow">
            <div
              className="flex justify-end items-center">
              <SearchInput
                inputEl={inputEl}
                searchSet={searchSet}
                search={search}
                searchExpand={searchExpand}
                onSearchSubmit={onSearchSubmit} />
              <div className="flex flex-col items-end">
                <button
                  className="min-w-0 p-2 text-true-white focus:outline-none flex-shrink-0"
                  aria-label="search"
                  aria-expanded={searchExpand}
                  onClick={onSearchClick}>
                  <div className="bc-ic-search text-3xl" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {searchExpand && (
        <div className="bg-nav flex items-center h-20 px-4 md:hidden">
          <SearchInput
            mobile
            inputEl={inputElMobile}
            searchSet={searchSet}
            search={search}
            searchExpand={searchExpand}
            onSearchSubmit={onSearchSubmit} />
        </div>
      )}
    </React.Fragment>
  )
}

export default MenuHead
