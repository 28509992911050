import React from 'react'
import Link from 'next/link'
import clsx from 'clsx'

import {sendGtagEvent} from '../../modules/ga'
import {useMenus} from '~/modules/menus'
import {useTheme} from '~/modules/theme'
import MenuSideArchives from './MenuSideArchives'

function MenuSide({menuSideExpand}) {
  const {themeMode, themeModeSet} = useTheme()
  const menus = useMenus()
  const isDarkTheme = themeMode === 'dark'

  return (
    <React.Fragment>
      <nav>
        <label
          htmlFor="themeToggle"
          className="flex items-center justify-between cursor-pointer px-3 py-4 bg-nav">
          <div className="font-fc text-true-white">
            Dark Mode
          </div>
          <div className="relative">
            <input
              checked={isDarkTheme}
              onChange={() => themeModeSet(isDarkTheme ? 'light' : 'dark')}
              id="themeToggle"
              type="checkbox"
              className="hidden" />
            <div className={clsx('w-8 h-4 bg-secondary rounded-full shadow-inner')} />
            <div className={clsx('absolute transform transition-all duration-100 ease-in-out w-4 h-4 bg-toggle rounded-full shadow inset-y-0', {
              'translate-x-full': isDarkTheme,
              'translate-x-0': !isDarkTheme,
            })} />
          </div>
        </label>
        <ul>
          {menus
            .filter(({shows}) => shows.includes('menuside'))
            .map(({
              title, href, as, template, rel, target,
            }, offset) => {
              if (template) {
                switch (template) {
                  case 'archive':
                    return (
                      <li key="archive">
                        <div className="font-fc text-true-white font-bold px-4 py-2">
                          Archives
                        </div>
                        <div>
                          {menuSideExpand && (
                            <MenuSideArchives offset={offset} />
                          )}
                        </div>
                      </li>
                    )
                  default:
                    throw new Error(`Unexpected template ${template}`)
                }
              }
              else {
                return (
                  <li key={as ?? href}>
                    <Link
                      href={href}
                      as={as}>
                      <a
                        target={target}
                        className="font-fc text-true-white font-bold hover:text-secondary active:text-secondary px-4 py-2 block w-full"
                        onClick={() => sendGtagEvent('click', {category: 'layouts/default/MenuSide', label: `menu-offset-${offset}`})}
                        rel={rel}>
                        {title}
                      </a>
                    </Link>
                  </li>
                )
              }
            })
          }
        </ul>
      </nav>
      <ul className="absolute inset-x-0 bottom-0 flex justify-evenly items-center py-3">
        {[
          {icon: 'bc-ic-facebook', href: 'https://www.facebook.com/BrandCaseTH'},
          {icon: 'bc-ic-blockdit', href: 'https://www.blockdit.com/brandcase '},
          {icon: 'bc-ic-instagram', href: 'https://instagram.com/brandcaseth'},
          {icon: 'bc-ic-twitter', href: 'https://twitter.com/brandcaseth'},
          {icon: 'bc-ic-youtube', href: 'https://www.youtube.com/channel/UCIJa-0_9Brk9SneuzD-RtbQ'},
        ].map(({icon, href}) => (
          <li key={icon}>
            <a
              className="text-black hover:text-black"
              target="_blank"
              rel="noopener noreferrer"
              href={href}>
              <div className={`${icon} text-2xl text-secondary`} />
            </a>
          </li>
        ))}
      </ul>
    </React.Fragment>
  )
}

export default MenuSide
