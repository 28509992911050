const prefix = 'brandcase'
const separator = '-'

const _localStorage = {
  getItem: key => window.localStorage.getItem([prefix, key].join(separator)),
  setItem: (key, value) => window.localStorage.setItem([prefix, key].join(separator), value),
  clear: () => window.localStorage.clear(),
}

export const localStorage = Object.keys(_localStorage).reduce((previous, current) => ({
  ...previous,
  [current]: (...args) => {
    try {
      return _localStorage[current](...args)
    }
    catch (error) {
      return null
    }
  },
}), {})