import React from 'react'
import Link from 'next/link'

function Footer() {
  return (
    <div className="text-center p-6">
      © 2024 BrandCase. All rights reserved. <Link href="/privacy"><a
        className="text-primary-reverse hover:underline"
        target="_blank">Privacy Policy.</a></Link>
    </div>
  )
}

export default Footer
