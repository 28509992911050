import Cookies from 'js-cookie'
import React from 'react'

const COOKIE_NAME = 'bc-theme'

const getBrowserTheme = () => {
  const mql = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')

  return mql && mql.matches ? 'dark' : 'light'
}

const getCookies = () => {
  try {
    return Cookies.get(COOKIE_NAME)
  }
  catch (error) {
    return 'light'
  }
}


export function useTheme() {
  const [themeMode, themeModeSet] = React.useState(null)

  React.useEffect(() => {
    const themeModeCookie = getCookies()

    if (typeof themeModeCookie !== 'undefined' && ['dark', 'light'].includes(themeModeCookie)) {
      themeModeSet(themeModeCookie)
    }
    else {
      themeModeSet(getBrowserTheme())
    }
  }, [])

  React.useEffect(() => {
    document.body.classList.toggle('theme-light', themeMode === 'light')
    document.body.classList.toggle('theme-dark', themeMode === 'dark')
  }, [themeMode])

  return {
    themeMode,
    themeModeSet: themeMode => {
      themeModeSet(themeMode)
      Cookies.set(COOKIE_NAME, themeMode, {expires: 365})
    },
  }
}
