import React, {useState, useEffect} from 'react'
import Head from 'next/head'
import {useRouter} from 'next/router'
import clsx from 'clsx'

import {themePath, LinkCss} from '~/utils/theme'
import {MenusProvider} from '~/modules/menus'

import AdUnit from '../../components/AdUnit'
import MenuHead from './MenuHead'
import MenuSide from './MenuSide'
import Footer from './Footer'
import menus from './menus'
import PrivacyPermissionCard from '~/components/PrivacyPermissionCard'

function useMenuSide() {
  const [menuSideExpand, menuSideExpandSet] = useState(false)
  const router = useRouter()

  useEffect(() => {
    if (menuSideExpand) {
      document.body.style.setProperty('overflow', 'hidden')
    }
    else {
      document.body.style.removeProperty('overflow')
    }
  }, [menuSideExpand])

  useEffect(() => {
    menuSideExpandSet(false)
  }, [router.asPath])

  return {
    menuSideExpand,
    menuSideExpandSet,
  }
}

function useSearch() {
  const router = useRouter()

  return {
    onSearch: search => {
      router.push({pathname: '/', query: {s: search}})
      window.scrollTo({top: 0})
    },
  }
}

export function DefaultLayoutWithoutMenu({children}) {
  const {menuSideExpand, menuSideExpandSet} = useMenuSide()
  const {onSearch} = useSearch()

  return (
    <React.Fragment>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta
          key="charSet"
          charSet="utf-8" />
        <link
          rel="stylesheet"
          href={themePath('tailwindcss')} />
      </Head>
      <main
        className={clsx('w-full bg-primary min-h-screen relative transition-all duration-100 ease-in-out pb-32', {'expanded': menuSideExpand})}
        data-testid="layouts/default">
        <div className="pt-navbar">
          <div className="pt-6">
            <div className="ad hidden md:flex justify-center mb-6">
              <AdUnit name="bc-billboard" />
            </div>
            <div>
              {children}
            </div>
          </div>
        </div>
        <nav className="fixed top-0 w-full">
          <MenuHead
            onClickMenu={() => menuSideExpandSet(!menuSideExpand)}
            onSearch={onSearch} />
        </nav>
        <div
          data-testid="layouts/default/MenuSide"
          style={{width: 240}}
          className="menu-side transform z-modal top-0 left-0 bg-nav fixed h-full overflow-auto ease-in-out transition-all duration-100">
          <div
            style={{width: 240}}
            className="relative min-h-full pb-24">
            <MenuSide menuSideExpand={menuSideExpand} />
          </div>
        </div>
        <div
          className="modal-backdrop z-backdrop fixed inset-0 transition-opacity">
          <div
            data-testid="layouts/default/Backdrop"
            onClick={() => menuSideExpandSet(false)}
            className="absolute inset-0 bg-true-black bg-opacity-50" />
        </div>
        <div className="absolute inset-x-0 bottom-0">
          <Footer />
        </div>
        <PrivacyPermissionCard />
      </main>
      <LinkCss
        key="bc-webfonts"
        href={themePath('bc-webfonts')} />
      <LinkCss
        key="fc-subject-rounded-webfonts"
        href={themePath('fc-subject-rounded-webfonts')} />
      <style
        jsx
        global>{`
        $menuSideWidth: 240px;
        main {          
          .modal-backdrop {
            display: none;
          }
          
          .menu-side {
            left: -$menuSideWidth;
            opacity: 0;
          }

          &.expanded {
            margin-left: $menuSideWidth;
                        
            .modal-backdrop {
              display: block;
            }
            
            .menu-side {
              left: 0;
              opacity: 1;
            }
          }
        }
        .ad {
          & iframe {
            max-width: 100%;
          }
        }
      `}</style>
    </React.Fragment>
  )
}


function DefaultLayout({children}) {
  return (
    <MenusProvider value={menus}>
      <DefaultLayoutWithoutMenu>{children}</DefaultLayoutWithoutMenu>
    </MenusProvider>
  )
}

DefaultLayout.menus = menus
export default DefaultLayout