import {gql as _gql} from '@apollo/client'

export function findFragments(operation, fragments) {
  const names = (operation.match(/\.{3}\s*\w+Fragment/g) || [])
    .map(name => name.match(/\.{3}\s*(\w+)Fragment/)[1])

  return names.reduce((foundFragments, name) => {
    if (name in foundFragments) {
      return foundFragments
    }
    else if (name in fragments) {
      const def = fragments[name]
      const op = def.kind && def.kind === 'Document'
        ? def.loc.source.body
        : def

      return {
        ...foundFragments,
        [name]: op,
        ...findFragments(op, fragments),
      }
    }
    else {
      throw new Error(`${name}Fragment not found in fragments.`)
    }
  }, {})
}

// Inspired from: node_modules/graphql-tag/src/index.js
export default function gql([literal0, ...literals], ...args) {
  const definitions = args
    .map((arg, i) => ([arg, literals[i]]))
    .reduce(
      (defs, [arg, literal]) => [...defs, arg, literal],
      [literal0],
    )

  const fragmentDefs = definitions.filter(def => def.fragments)

  if (fragmentDefs.length > 1) {
    throw new Error(`Found ${fragmentDefs.length} fragments. Expect <= 1.`)
  }
  const fragments = (fragmentDefs[0] || {fragments: {}}).fragments

  const operation = definitions
    .map(def => {
      if (def.kind && def.kind === 'Document') {
        return def.loc.source.body
      }
      else if (def.fragments) {
        return ''
      }
      else if (['string', 'number'].includes(typeof def)) {
        return def
      }
      else {
        throw new Error(`Found ${(typeof def)}. Expect string, Document, or fragments.`)
      }
    })
    .join('')

  const foundFragments = Object.keys(fragments).length > 0
    ? findFragments(operation, fragments)
    : {}

  return _gql`
    ${operation}
    ${Object.values(foundFragments).map(op => op)}
  `
}
