import React, {useState, useEffect, useRef} from 'react'
import {useRouter} from 'next/router'

import config from '~/config'
import {useMenu} from '~/modules/menus'

const local = {counter: 0}

function useDfpSlot({name, targets, fnGetParent}) {
  const [ready, readySet] = useState(false)
  const [delivered, deliveredSet] = useState(false)
  const slot = useRef(null)
  const divEl = useRef(null)

  const {networkID, adUnits} = config.dfp
  const sizes = adUnits[name]

  useEffect(() => {
    googletag.cmd.push(() => {
      readySet(true)
    })
  }, [])

  useEffect(() => {
    if (!ready || divEl.current === null) {
      return
    }

    const divId = `ad-${local.counter++}`

    divEl.current.id = divId

    let pollRemains = 200
    const intervalPollDimension = setInterval(() => {
      const {height} = divEl.current.getBoundingClientRect()

      if (height > 50) {
        deliveredSet(true)
        clearInterval(intervalPollDimension)
      }
      else if (--pollRemains < 0) {
        clearInterval(intervalPollDimension)
      }
    }, 100)

    const parentWidth = fnGetParent(divEl.current).getBoundingClientRect().width
    const slotSizes = sizes.filter(([width]) => width <= parentWidth)

    if (slotSizes.length === 0) {
      clearInterval(intervalPollDimension)

      return
    }

    googletag.cmd.push(() => {
      slot.current = googletag.defineSlot(`/${networkID}/${name}`, slotSizes, divId)
      slot.current.addService(googletag.pubads())
      if (targets) {
        Object.keys(targets).forEach(key => {
          const val = targets[key]

          slot.current.setTargeting(key, val)
        })
      }
      googletag.display(divId)
      googletag.pubads().refresh([slot.current])
    })

    return () => {
      clearInterval(intervalPollDimension)
      googletag.cmd.push(() => {
        googletag.pubads().clear([slot.current])
        googletag.destroySlots([slot.current])
      })
    }
  }, [ready, name, JSON.stringify(targets)])

  return {divEl, delivered}
}

function AdUnit({name, targets, collapsible = false}) {
  const router = useRouter()
  const {extras} = useMenu()
  const [show, showSet] = useState(true)

  const path = router.route
    .split('/')
    .map(chunk => {
      const m = (/^\[(.+)\]/).exec(chunk)

      return m !== null && m[1] in router.query
        ? router.query[m[1]]
        : chunk
    })
    .join('/')

  const combinedTargets = {
    path,
    route: router.route.replace(/\[/g, '{').replace(/\]/g, '}'),
    ...Object.keys(router.query)
      .reduce(
        (z, k) => ({...z, [`query.${k}`]: router.query[k]}),
        {},
      ),
    ...extras.targets,
    ...targets,
  }

  const fnGetParent = collapsible ? n => n.parentNode.parentNode : n => n.parentNode
  const {divEl, delivered} = useDfpSlot({name, targets: combinedTargets, fnGetParent})

  // do not show ad if query.t is present
  if ('t' in router.query) {
    return null
  }

  if (collapsible) {
    if (show) {
      return (
        <React.Fragment>
          <div className="flex justify-center">
            <div ref={divEl} />
          </div>
          {delivered && (
            <div className="flex justify-center">
              <button
                onClick={() => showSet(false)}
                className="text-sm">
                Close Ad
              </button>
            </div>
          )}
        </React.Fragment>
      )
    }
    else {
      return null
    }
  }
  else {
    return <div ref={divEl} />
  }
}

export default AdUnit
