import React, {useState} from 'react'
import Link from 'next/link'
import {useQuery} from '@apollo/client'

import {sendGtagEvent} from '../../modules/ga'
import {useTranslation} from '~/i18n'
import qlArchives from '~/graphql/Archives'

export function useArchives() {
  const {loading, data} = useQuery(qlArchives, {variables: {withPosts: false}})
  const ready = !loading && typeof data !== 'undefined'

  if (!ready) {
    return {ready}
  }

  const archives = {}

  data.archives.forEach(archive => {
    const {year} = archive

    if (year in archives) {
      archives[year] = [...archives[year], archive]
    }
    else {
      archives[year] = [archive]
    }
  })

  return {ready, archives}
}

function MenuItemArchive({archives, year, offsets}) {
  const {t} = useTranslation('datetime')
  const [expand, setExpand] = useState(false)

  return (
    <React.Fragment>
      <li>
        <button
          className="font-fc text-true-white font-bold hover:text-secondary active:text-secondary  pl-8 pr-4 py-2 block w-full text-left"
          data-testid={`layouts/default/MenuSide/MenuArchive-${year}`}
          onClick={() => {
            setExpand(!expand)
            sendGtagEvent('click', {category: 'layouts/default/MenuSide', label: `menu-offset-${offsets.join('.')}`})
          }}>
          {year}
        </button>
      </li>
      {expand && archives[year].map(({year, month: _month}, i3) => {
        const month = `0${_month}`.slice(-2)

        return (
          <Link
            key={`${year}-${month}`}
            href="/date/[year]/[month]"
            as={`/date/${year}/${month}`}
            passHref>
            <a
              className="font-fc text-true-white font-bold hover:text-secondary active:text-secondary pl-12 pr-4 py-2 block w-full text-left"
              data-testid={`layouts/default/MenuSide/MenuArchive-${year}-${month}`}
              onClick={() => sendGtagEvent('click', {category: 'layouts/default/MenuSide', label: `menu-offset-${[...offsets, i3].join('.')}`})}>
              {t('month.long', {month})}
            </a>
          </Link>
        )
      })}
    </React.Fragment>
  )
}

function MenuSideArchives({offset}) {
  const {ready, archives} = useArchives()

  if (!ready) {
    return (
      <div className="pl-4">
        <svg
          className="animate-spin h-5 w-5 text-secondary"
          viewBox="0 0 24 24">
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      </div>
    )
  }

  return (
    <ul>
      {Object.keys(archives).reverse().map((year, i) => (
        <MenuItemArchive
          key={`${year}`}
          archives={archives}
          year={year}
          offsets={[offset, i]} />
      ),
      )}
    </ul>
  )
}

export default MenuSideArchives
