import React from 'react'
import {useRouter} from 'next/router'

const MenusContext = React.createContext([
  {
    title: 'HOME',
    href: '/',
    extras: {logoBrand: true},
    shows: ['menuhead', 'menuside'],
  },
])

export const MenusProvider = MenusContext.Provider

export function useMenus() {
  return React.useContext(MenusContext)
}

export function getMenu(menus, asPath) {
  if ((/\/date\/\d{4}\/\d{2}$/).test(asPath)) {
    return {
      title: 'บทความที่ผ่านมา',
      href: '/date/[year]/[month]',
      as: asPath,
      extras: {},
    }
  }

  return menus.find(menu => menu.as === decodeURI(asPath)) ?? menus[0]
}

export function useMenu() {
  const router = useRouter()
  const menus = useMenus()

  return getMenu(menus, router.asPath)
}

export function isMenuActive({menu, router}) {
  const {href, as} = menu

  return href === router.route &&
    [href, as].includes(toAs(router.route, router.query))
}

function toAs(route, query) {
  return Object.keys(query).reduce(
    (z, k) => z.split(`/[${k}]`).join(`/${query[k]}`),
    route,
  )
}
